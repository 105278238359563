import { Dictionary } from "~/utils/language";
import { useRootData } from "./common";

export function useLocale() {
	const data = useRootData();
	const locale = data?.locale;

	return locale;
}

export function useSharedContent(dictionaryKey = Dictionary.COMMON) {
	const data = useRootData();
	const locale = data?.locale;

	const sharedContent =
		data && data.sharedContent ? data.sharedContent[dictionaryKey] : null;

	function t(key: string, fallbackText?: string): string {
		if (!sharedContent) {
			console.warn(`Dictionary with key ${dictionaryKey} not found`);

			return key;
		}

		const localizedText = sharedContent[key];

		if (!localizedText) {
			console.warn(
				`Text with key ${key} hasn't been translated into ${locale}`
			);
		}

		return localizedText || fallbackText || key || "";
	}

	return { t };
}
